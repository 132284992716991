import React from "react"
import { Link } from "gatsby"
import Layout from '../components/layout'
import Head from '../components/head'
import ReceiverBoxSort from "../components/receiver_box_sort"

import recStyles from "../components/receiver_box_sort.module.scss"


const Receivers = () => {

    return (
        <Layout>
            <Head
                title="NFL Receiving Leaders"
                pageDescription="Yards, EPA, Target Share, and other advanced stats for Wide Receivers, Tight End, and Running Backs"
            />
            <div className={recStyles.title_box}>
                <h1 className={recStyles.title}>
                    NFL Receiving Leaders
                </h1>
                <h2 className={recStyles.sub_title}>
                    Yards, EPA, Target Share, and other advanced stats for Wide Receivers, Tight End, and Running Backs
                </h2>
            </div>
            <ReceiverBoxSort/>
            <div className={recStyles.content_container}>
                <h3 className={recStyles.content_h3}>
                    Season Stats
                </h3>
                <p className={recStyles.content_p}>
                    The season stats columns contain all the traditional receiving stats you’d expect to see for Wide Receivers, Tight Ends, and Running Backs. This includes things like total receiving yards, yards per catch, touchdowns, etc.
                </p>
                <h3 className={recStyles.content_h3}>
                    EPA
                </h3>
                <p className={recStyles.content_p}>
                    EPA stands for Expected Points Added, and is one of the most commonly used advanced metrics for measuring performance. EPA looks at how a player’s production translates into actual points scored and includes context for game situation.
                </p>
                <Link
                   className={recStyles.articleLink}
                   to="/analysis/expected-points-added-epa-nfl/"
               >
                   Read more about EPA here
               </Link>
                <p className={recStyles.content_p}>
                    Here, we’ve broken down EPA by game, targets, receptions, and routes.
                </p>
               <h3 className={recStyles.content_h3}>
                    Target Share
                </h3>
                <p className={recStyles.content_p}>
                    Target share is the percent of the team’s passes that were targeted at the receiver. Better receivers tend to get more targets, with the league’s best receivers earning up to 35%. Target share is also broken down by third down and red zone.
                </p>
                <h3 className={recStyles.content_h3}>
                    Target Rate
                </h3>
                <p className={recStyles.content_p}>
                    Target rate is the percent of routes the receiver ran that resulted in a target. Good receivers that get open more often tend to get more targets per route.
                </p>
                <h3 className={recStyles.content_h3}>
                    Catch Rate
                </h3>
                <p className={recStyles.content_p}>
                    Catch rate is the percent of targets that resulted in a catch. Better receivers tend to catch more of the balls thrown their way, but it is important to adjust for context, like how deep the receiver was when the ball was thrown.
                </p>
                <h3 className={recStyles.content_h3}>
                    Catch Rate Over Expected
                </h3>
                <p className={recStyles.content_p}>
                    Catch Rate Over Expected (CROE) is an advanced metric that measures how much higher or lower a receiver’s catch rate is relative to what would be expected based on the types of plays they were targeted on. Deep passes to the sideline have lower completion probability and thus reward receivers with more CROE when they are caught.
                </p>
                <h3 className={recStyles.content_h3}>
                    Average Depth of Target
                </h3>
                <p className={recStyles.content_p}>
                    Average Depth of Target (aDOT) measures how deep a receiver is, on average, when targeted by the QB. Deep threat receivers tend to have high aDOT while running backs that receive checkdowns or passes to the flats tend to have low aDOT.
                </p>
            </div>
        </Layout>
    )
}

export default Receivers

