import React, { useState } from "react"
import {graphql, useStaticQuery} from "gatsby"

import recStyles from './receiver_box_sort.module.scss'
import SortableTable from "./Table/table"
import TopTableBar from "./Table/Extras/top_bar"


const ReceiverBoxSort = () => {

    // temp fillers for testing //
    const tempFields = [
        {
            'displayName' : 'Season',
            'refName' : 'season',
            'fieldGroup' : 'Season',
            'displayType' : 'AsInt',
            'displayParams' : {},
        },
        {
            'displayName' : 'GP',
            'refName' : 'games_played',
            'fieldGroup' : 'Season',
            'displayType' : 'AsInt',
            'displayParams' : {},
        },
        {
            'displayName' : 'Targets',
            'refName' : 'targets',
            'fieldGroup' : 'Season',
            'displayType' : 'AsInt',
            'displayParams' : {},
        },
        {
            'displayName' : 'Recs',
            'refName' : 'receptions',
            'fieldGroup' : 'Season',
            'displayType' : 'AsInt',
            'displayParams' : {},
        },
        {
            'displayName' : 'Yards',
            'refName' : 'yards',
            'fieldGroup' : 'Season',
            'displayType' : 'AsInt',
            'displayParams' : {},
        },
        {
            'displayName' : 'YPC',
            'refName' : 'yards_per_catch',
            'fieldGroup' : 'Season',
            'displayType' : 'AsFloat',
            'displayParams' : {'percision' : 1},
        },
        {
            'displayName' : 'YPG',
            'refName' : 'yards_per_game',
            'fieldGroup' : 'Season',
            'displayType' : 'AsFloat',
            'displayParams' : {'percision' : 1},
        },
        {
            'displayName' : 'TDs',
            'refName' : 'tds',
            'fieldGroup' : 'Season',
            'displayType' : 'AsInt',
            'displayParams' : {},
        },
        {
            'displayName' : '1st Downs',
            'refName' : 'first_downs',
            'fieldGroup' : 'Season',
            'displayType' : 'AsInt',
            'displayParams' : {},
        },
        {
            'displayName' : 'Total',
            'refName' : 'epa',
            'fieldGroup' : 'EPA',
            'displayType' : 'AsFloat',
            'displayParams' : {'percision' : 1},
        },
        {
            'displayName' : '/ Game',
            'refName' : 'epa_per_game',
            'fieldGroup' : 'EPA',
            'displayType' : 'AsFloat',
            'displayParams' : {'percision' : 1},
        },
        {
            'displayName' : '/ Catch',
            'refName' : 'epa_per_catch',
            'fieldGroup' : 'EPA',
            'displayType' : 'AsFloat',
            'displayParams' : {'percision' : 2},
        },
        {
            'displayName' : '/ Target',
            'refName' : 'epa_per_target',
            'fieldGroup' : 'EPA',
            'displayType' : 'AsFloat',
            'displayParams' : {'percision' : 2},
        },
        {
            'displayName' : '/ Route',
            'refName' : 'epa_per_route',
            'fieldGroup' : 'EPA',
            'displayType' : 'AsFloat',
            'displayParams' : {'percision' : 3},
        },
        {
            'displayName' : 'All',
            'refName' : 'target_share',
            'fieldGroup' : 'Target Share',
            'displayType' : 'TargetShare',
            'displayParams' : {},
        },
        {
            'displayName' : '3rd Down',
            'refName' : 'target_share_third_dwon',
            'fieldGroup' : 'Target Share',
            'displayType' : 'TargetShare',
            'displayParams' : {},
        },
        {
            'displayName' : 'Red Zone',
            'refName' : 'target_share_rz',
            'fieldGroup' : 'Target Share',
            'displayType' : 'TargetShare',
            'displayParams' : {},
        },
        {
            'displayName' : 'Target Rate',
            'refName' : 'target_rate',
            'fieldGroup' : 'Target Detail',
            'displayType' : 'AsPct',
            'displayParams' : {'percision' : 1  },
        },
        {
            'displayName' : 'Catch Rate',
            'refName' : 'catch_rate',
            'fieldGroup' : 'Target Detail',
            'displayType' : 'AsPct',
            'displayParams' : {'percision' : 1  },
        },
        {
            'displayName' : 'CROE',
            'refName' : 'avg_cpoe',
            'fieldGroup' : 'Target Detail',
            'displayType' : 'Cpoe',
            'displayParams' : {'rem' : '0.85rem'},
        },
        {
            'displayName' : 'aDOT',
            'refName' : 'adot',
            'fieldGroup' : 'Target Detail',
            'displayType' : 'AsFloat',
            'displayParams' : {'percision' : 1},
        },

    ]

    const defaultSort = [{
        'field' : 'yards',
        'direction' : 'dsc'
    }]


    // data //
    const data = useStaticQuery(graphql`
        query {
            allWrStatsCsv {
                nodes {
                    unique_id
                    offensive_participant
                    first_name
                    last_name
                    full_name
                    headshot_url
                    position
                    team_logo_espn
                    team
                    team_nick
                    season
                    games_played
                    targets
                    receptions
                    yards
                    tds
                    first_downs
                    yards_per_catch
                    yards_per_game
                    epa
                    epa_per_game
                    epa_per_catch
                    epa_per_target
                    epa_per_route
                    target_share
                    target_share_third_dwon
                    target_share_rz
                    target_rate
                    catch_rate
                    adot
                    avg_cpoe
                    team_color
                    team_color2
                }
            }
        }      
    `)
    
    // unique values for filtering //

    const seasons = []
    const players = []
    const positions = ['WR' , 'TE', 'RB', 'FB']
    const teams = []

    data.allWrStatsCsv.nodes.forEach((row) => {

        if (!seasons.includes(row['season'])) {
            seasons.push(row['season'])
        }

        if (!players.includes(row['full_name'])) {
            players.push(row['full_name'])
        }

        if (!teams.includes(row['team_nick'])) {
            teams.push(row['team_nick'])
        }
    })



    // translate into dropdown options //
    const seasonOptions = seasons.map((row) => {
        return (
            {'value' : parseInt(row), 'label' : row}
        )
    })
    const playerOptions = players.map((row) => {
        return (
            {'value' : row, 'label' : row}
        )
    })
    const positionOptions = positions.map((row) => {
        return (
            {'value' : row, 'label' : row}
        )
    })
    const teamOptions = teams.map((row) => {
        return (
            {'value' : row, 'label' : row}
        )
    })

    // starting vals //
    const currentSeason = Math.max(...seasons)

    // drop states //
    // in logic, if array is blank, then we will take all //
    const [selectedSeasons, setSelectedSeasons] = useState([
        {'value' : currentSeason, 'label' : currentSeason}
    ])

    const [selectedPlayers, setSelectedPlayers] = useState([])
    const [selectedPositions, setSelectedPositions] = useState([])
    const [selectedTeams, setSelectedTeams] = useState([])
    const [selectedTargets, setSelectedTargets] = useState('')
    
    const updateTargets = event => {
        setSelectedTargets(event.target.value)
    }
    // filter data //
    // first create arrays of selected values that can be checked //
    // as we iterate over the dataset //

    // seasons //
    const seasonVals = selectedSeasons.map((season) => {
        return(
            season['value']
        )
    })

    //players //
    const playerVals = selectedPlayers.map((player) => {
        return(
            player['value']
        )
    })

    // teams //
    const teamVals = selectedTeams.map((team) => {
        return(
            team['value']
        )
    })

    // positions //
    const posVals = selectedPositions.map((pos) => {
        return(
            pos['value']
        )
    })

    const filteredData = data.allWrStatsCsv.nodes.filter((row) => {

        // boolean to toggle if row is included //
        var includeBool = 1

        // seasons //
        if ((seasonVals.length === 0 && parseInt(row['season']) === currentSeason) || seasonVals.includes(parseInt(row['season']))) {
        
        } else {
            includeBool = includeBool * 0
        }

        // teams //
        if (teamVals.length === 0 || teamVals.includes(row['team_nick'])) {

        } else {
            includeBool = includeBool * 0
        }
      
        // players //
        if (playerVals.length === 0 || playerVals.includes(row['full_name'])) {

        } else {
            includeBool = includeBool * 0
        }

        // pos //
        if (posVals.length === 0 || posVals.includes(row['position'])) {

        } else {
            includeBool = includeBool * 0
        }

        // targets //
        if (isNaN(parseInt(selectedTargets))) {

        } else if (parseInt(row['targets']) < parseInt(selectedTargets)) {
            includeBool = includeBool * 0
        }
              
        // if bool still 1 after filters, then add //
        if (includeBool === 1) {
            return (true)
        } else {
            return (false)
        }

    })

    // logic for handling scroll //
    const [tableScrolled, setTableScrolled] = useState(false)

    const handleScroll = e => {
        setTableScrolled(
            e.target.scrollLeft > 40
        )
    }

    // filters //
    const tableFilters = [
        {
            'filterId' : 'seasons',
            'filterName' : 'Seasons',
            'filterType' : 'multiSelect',
            'filterParams' : {
                'options' : seasonOptions,
                'onChange' : setSelectedSeasons,
                'values' : selectedSeasons,
                'default' : null
            }
        },
        {
            'filterId' : 'teams',
            'filterName' : 'Teams',
            'filterType' : 'multiSelect',
            'filterParams' : {
                'options' : teamOptions,
                'onChange' : setSelectedTeams,
                'values' : selectedTeams,
                'default' : null
            }
        },
        {
            'filterId' : 'positions',
            'filterName' : 'Positions',
            'filterType' : 'multiSelect',
            'filterParams' : {
                'options' : positionOptions,
                'onChange' : setSelectedPositions,
                'values' : selectedPositions,
                'default' : null
            }
        },
        {
            'filterId' : 'players',
            'filterName' : 'Players',
            'filterType' : 'multiSelect',
            'filterParams' : {
                'options' : playerOptions,
                'onChange' : setSelectedPlayers,
                'values' : selectedPlayers,
                'default' : null
            }
        },
        {
            'filterId' : 'minTargets',
            'filterName' : 'Min. Targets',
            'filterType' : 'numInput',
            'filterParams' : {
                'values' : selectedTargets,
                'onChange' : updateTargets
            }
        },
    ]

    

    return (

        <div className={recStyles.team_container}>
            <TopTableBar
                tableData={filteredData}
                ignores={[]}
                fileName={'nfl-receiving-leaders.csv'}
                filters={tableFilters}
            />
            <div className={recStyles.team_table_container} onScroll={handleScroll}>
                <SortableTable
                    records={filteredData}
                    fields={tempFields}
                    plateType={'player'}
                    defaultSort={defaultSort}
                    linkType={'none'}
                    idField={'unique_id'}
                    tableScrolled={tableScrolled}
                />    
            </div>
        </div>
    )
}

export default ReceiverBoxSort